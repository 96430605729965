import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Rocky Mountain Reserve",
      keywords: ["home", ""]
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login/Login.vue"),
    meta: {
      title: "Login | RMR",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "Login / Register" }],
      breadcrumbLight: true,
      noSearch: true
    }
  },
  {
    path: "/login/employer",
    name: "Employer Login",
    component: () => import("../views/Login/employer/EmployerLogin.vue"),
    meta: {
      title: "Employer Login | Rocky Mountain Reserve",
      breadcrumb: [
        { name: "Home", link: "/" },
        { name: "Login / Register", link: "/login" },
        { name: "Employer Login / Registration" }
      ],
      breadcrumbLight: true,
      keywords: ["login", "employer login"]
    }
  },
  {
    path: "/login/participant",
    name: "Participant Login",
    component: () => import("../views/Login/participant/ParticipantLogin.vue"),
    meta: {
      title: "Participant Login | Rocky Mountain Reserve",
      breadcrumb: [
        { name: "Home", link: "/" },
        { name: "Login / Register", link: "/login" },
        { name: "Participant Login / Registration" }
      ],
      breadcrumbLight: true,
      keywords: ["login", "participant login"]
    }
  },
  {
    path: "/about",
    name: "About Us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutUs/About.vue"),
    meta: {
      title: "About Us | Rocky Mountain Reserve",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "About Us" }],
      breadcrumbLight: true,
      keywords: ["about", "about us"]
    }
  },
  {
    path: "/privacy",
    name: "Privacy Policy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/Privacy/Privacy.vue"),
    meta: {
      title: "Privacy Policy | Rocky Mountain Reserve",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "Privacy Policy" }],
      keywords: ["privacy", "privacy policy"]
    }
  },
  {
    path: "/whats-covered",
    name: "What's Covered",
    component: () => import("../views/WhatsCovered/WhatsCovered.vue"),
    meta: {
      title: "What's Covered | Rocky Mountain Reserve",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "What's Covered" }],
      breadcrumbLight: true,
      keywords: ["eligible", "what's covered", "can I use", "what can I use"]
    }
  },
  {
    path: "/whats-covered-2",
    name: "What's Covered 2.0",
    component: () => import("../views/WhatsCovered/WhatsCovered2.vue"),
    meta: {
      title: "What's Covered 2.0 | Rocky Mountain Reserve",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "What's Covered 2.0" }],
      breadcrumbLight: true,
      keywords: ["eligible", "what's covered", "can I use", "what can I use"]
    }
  },
  {
    path: "/whatscovered",
    redirect: "/whats-covered"
  },
  {
    path: "/how-to-videos",
    name: "How To Videos",
    component: () => import("../views/HowToVideos/HowToVideos.vue"),
    meta: {
      title: "How-To Videos | Rocky Mountain Reserve",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "How To Videos" }],
      keywords: ["videos"]
    }
  },
  {
    path: "/how-to-videos/:video",
    name: "How to",
    component: () => import("../views/HowToVideos/CurrentHowToVideo.vue"),
    meta: {
      title: "How to | Rocky Mountain Reserve",
      breadcrumb: [
        { name: "Home", link: "/" },
        { name: "How to Videos", link: "/how-to-videos" },
        { name: "How to" }
      ]
    }
  },
  {
    path: "/plan-videos",
    // redirect: "plan-videos/open-enrollment",
    name: "Plan Videos",
    component: () => import("../views/PlanVideos/PlanVideos.vue"),
    meta: {
      title: "Plan Videos | Rocky Mountain Reserve",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "Plan Videos" }]
    }
  },
  {
    path: "/plan-videos/:video",
    name: "Plan",
    component: () => import("../views/PlanVideos/CurrentVideo.vue"),
    meta: {
      title: "Plan | Rocky Mountain Reserve",
      breadcrumb: [
        { name: "Home", link: "/" },
        { name: "Plan Videos", link: "/plan-videos" },
        { name: "Plan" }
      ]
    }
  },
  {
    path: "/forms",
    name: "Forms",
    component: () => import("../views/Forms/Forms.vue"),
    meta: {
      title: "Forms | Rocky Mountain Reserve",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "Forms" }],
      breadcrumbLight: true,
      keywords: ["forms", "claim form", "direct deposit", "debit card request"]
    }
  },
  {
    path: "/calculators",
    name: "Calculators",
    component: () => import("../views/Calculators/Calculators.vue"),
    meta: {
      title: "Calculators | Rocky Mountain Reserve",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "Calculators" }],
      breadcrumbLight: true,
      keywords: [
        "calculators",
        "savings calculator",
        "long-term",
        "short-term",
        "plan comparison"
      ]
    }
  },
  {
    path: "/support",
    name: "Support & FAQ",
    component: () => import("../views/Support/Support.vue"),
    meta: {
      title: "Support & FAQ | Rocky Mountain Reserve",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "Support & FAQ" }],
      breadcrumbLight: true,
      keywords: ["support", "help", "faq", "help me"]
    }
  },

  {
    path: "/support/categories/:categoryId",
    name: "Support - Category",
    component: () => import("../views/Support/category/Category.vue"),
    meta: {
      title: "Support & FAQ | Rocky Mountain Reserve",
      breadcrumb: [
        { name: "Home", link: "/" },
        { name: "Support & FAQ", link: "/support" }
      ],
      noSearch: true
    }
  },
  {
    path: "/support/sections/:sectionId",
    name: "Support - Section",
    component: () => import("../views/Support/section/Section.vue"),
    meta: {
      title: "Support & FAQ | Rocky Mountain Reserve",
      breadcrumb: [
        { name: "Home", link: "/" },
        { name: "Support & FAQ", link: "/support" }
      ],
      noSearch: true
    }
  },
  {
    path: "/support/articles/:articleId",
    name: "Support - Article",
    component: () => import("../views/Support/article/Article.vue"),
    meta: {
      title: "Support & FAQ | Rocky Mountain Reserve",
      breadcrumb: [
        { name: "Home", link: "/" },
        { name: "Support & FAQ", link: "/support" }
      ],
      noSearch: true
    }
  },
  {
    path: "/support/search/:query",
    name: "Support - Search",
    component: () => import("../views/Support/searchResults/SearchResults.vue"),
    meta: {
      title: "Support & FAQ | Rocky Mountain Reserve",
      breadcrumb: [
        { name: "Home", link: "/" },
        { name: "Support & FAQ", link: "/support" },
        { name: "Search Results" }
      ],
      noSearch: true
    }
  },
  {
    path: "/support/search",
    redirect: "/support"
  },
  {
    path: "/contact",
    name: "Contact Us",
    component: () => import("../views/ContactUs/Contact.vue"),
    meta: {
      title: "Contact Us | Rocky Mountain Reserve",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "Contact Us" }],
      breadcrumbLight: true,
      keywords: [
        "call",
        "email",
        "phone number",
        "fax",
        "address",
        "business hours",
        "hours"
      ]
    }
  },
  {
    path: "/open-enrollment",
    redirect: "/employer-resources/open-enrollment"
  },
  {
    path: "/flex-enrollment",
    redirect: "/employer-resources/open-enrollment"
  },
  {
    path: "/cobra-enrollment",
    redirect: "/employer-resources/cobra-resources"
  },
  {
    path: "/employer-resources",
    redirect: "/employer-resources/open-enrollment",
    name: "Employer Resources",
    component: () => import("../views/EmployerResources/EmployerResources.vue"),
    meta: {
      title: "Employer Resources | Rocky Mountain Reserve",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "Employer Resources" }],
      breadcrumbLight: true
    },
    children: [
      {
        path: "open-enrollment",
        name: "Open Enrollment",
        component: () =>
          import("../views/EmployerResources/subnav/OpenEnrollment.vue"),
        meta: {
          title: "Open Enrollment | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Employer Resources", link: "/employer-resources" },
            { name: "Open Enrollment" }
          ],
          keywords: ["open enrollment"]
        }
      },
      {
        path: "plan-resources",
        name: "Plan Resources",
        component: () =>
          import(
            "../views/EmployerResources/subnav/PlanResources/PlanResources.vue"
          ),
        meta: {
          title: "Plan Resources | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Employer Resources", link: "/employer-resources" },
            { name: "Plan Resources" }
          ]
        }
      },
      {
        path: "cobra-resources",
        name: "COBRA Resources",
        component: () =>
          import("../views/EmployerResources/subnav/CobraResources.vue"),
        meta: {
          title: "COBRA Resources | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Employer Resources", link: "/employer-resources" },
            { name: "COBRA Resources" }
          ]
        }
      },
      {
        path: "virtual-benefit-fair",
        name: "Virtual Benefit Fair",
        component: () =>
          import("../views/EmployerResources/subnav/VirtualBenefitFair.vue"),
        meta: {
          title: "Virtual Benefit Fair | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Employer Resources", link: "/employer-resources" },
            { name: "Virtual Benefit Fair" }
          ],
          keywords: ["virtual benefit fair", "benefit fair"]
        }
      },
      {
        path: "templates",
        name: "Templates",
        component: () =>
          import("../views/EmployerResources/subnav/Templates.vue"),
        meta: {
          title: "Templates | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Employer Resources", link: "/employer-resources" },
            { name: "Templates" }
          ],
          keywords: ["templates"]
        }
      },
      {
        path: "materials",
        name: "Materials For Employees",
        component: () =>
          import("../views/EmployerResources/subnav/Materials.vue"),
        meta: {
          title: "Materials For Employees | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Employer Resources", link: "/employer-resources" },
            { name: "Materials For Employees" }
          ],
          keywords: ["enrollment guide"]
        }
      }
      // {
      //   path: "submitting-info",
      //   name: "Submitting Info to RMR",
      //   component: () =>
      //     import("../views/EmployerResources/subnav/SubmittingInfo.vue"),
      //   meta: {
      //     title: "Submitting Info to RMR | Rocky Mountain Reserve",
      //     breadcrumb: [
      //       { name: "Home", link: "/" },
      //       { name: "Employer Resources", link: "/employer-resources" },
      //       { name: "Submitting Info to RMR" }
      //     ]
      //   }
      // }
    ]
  },
  {
    path: "/services",
    name: "Our Services",
    component: () => import("../views/Services/Services.vue"),
    meta: {
      title: "Our Services | Rocky Mountain Reserve",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "Services" }]
    },
    children: [
      {
        path: "cafeteria",
        name: "Cafeteria Plan",
        component: () =>
          import("../views/Services/plans/cafeteria/Cafeteria.vue"),
        children: [
          {
            path: "example",
            name: "cafeteria/example",
            component: () =>
              import("../views/Services/plans/cafeteria/Cafeteria.vue"),
            props: { showExample: true },
            meta: {
              title: "Cafeteria Plan | Rocky Mountain Reserve",
              breadcrumb: [
                { name: "Home", link: "/" },
                { name: "Services", link: "/services" },
                { name: "Cafeteria Plans" }
              ],
              noSearch: true
            }
          }
        ],
        meta: {
          title: "Cafeteria Plan | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Services", link: "/services" },
            { name: "Cafeteria Plans" }
          ],
          keywords: ["cafeteria", "cafeteria plan"]
        }
      },
      {
        path: "fsa",
        name: "FSA",
        components: {
          default: () => import("../views/Services/plans/fsa/FSA.vue"),
          extras: () => import("../views/Services/plans/fsa/FSAExtras.vue")
        },
        meta: {
          title: "Flexible Spending Account | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Services", link: "/services" },
            { name: "Flexible Spending Accounts" }
          ],
          keywords: [
            "FSA",
            "flexible spending",
            "flex",
            "flexible spending account"
          ]
        }
      },
      {
        path: "dca",
        name: "DCA",
        components: {
          default: () => import("../views/Services/plans/dca/DCA.vue"),
          extras: () => import("../views/Services/plans/dca/DCAExtras.vue")
        },
        meta: {
          title: "Dependent Care Account | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Services", link: "/services" },
            { name: "Dependent Care Accounts" }
          ],
          keywords: [
            "DCA",
            "dependent care",
            "daycare",
            "child care",
            "Dependent Care Account"
          ]
        }
      },
      {
        path: "hsa",
        name: "HSA",
        components: {
          default: () => import("../views/Services/plans/hsa/HSA.vue"),
          extras: () => import("../views/Services/plans/hsa/HSAExtras.vue")
        },
        meta: {
          title: "Health Savings Account | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Services", link: "/services" },
            { name: "Health Savings Account" }
          ],
          keywords: ["HSA", "Health Savings", "Health Savings Account"]
        }
      },
      {
        path: "hra",
        name: "HRA",
        components: {
          default: () => import("../views/Services/plans/hra/HRA.vue"),
          extras: () => import("../views/Services/plans/hra/HRAExtras.vue")
        },
        meta: {
          title: "Health Reimbursement Arrangement | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Services", link: "/services" },
            { name: "Health Reimbursement Arrangement" }
          ],
          keywords: [
            "HRA",
            "Health Reimbursement",
            "Health Reimbursement Arrangement"
          ]
        }
      },
      {
        path: "transit",
        name: "Transit",
        components: {
          default: () => import("../views/Services/plans/transit/Transit.vue"),
          extras: () =>
            import("../views/Services/plans/transit/TransitExtras.vue")
        },
        meta: {
          title: "Qualified Transit/Parking Account | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Services", link: "/services" },
            { name: "Qualified Transit/Parking Accounts" }
          ],
          keywords: ["TRN", "transit", "PKG", "parking"]
        }
      },
      {
        path: "wellness",
        name: "Wellness",
        component: () =>
          import("../views/Services/plans/wellness/Wellness.vue"),
        meta: {
          title: "Wellness Accounts | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Services", link: "/services" },
            { name: "Wellness Account" }
          ],
          keywords: ["wellness account", "wellness"]
        }
      },
      {
        path: "lsa",
        name: "LSA",
        components: {
          default: () => import("../views/Services/plans/lsa/LSA.vue"),
          extras: () => import("../views/Services/plans/lsa/LSAExtras.vue")
        },
        meta: {
          title: "Lifestyle Spending Accounts | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Services", link: "/services" },
            { name: "Lifestyle Spending Account" }
          ],
          keywords: ["lifestyle spending account", "lsa"]
        }
      },
      {
        path: "tuition",
        name: "Tuition",
        component: () => import("../views/Services/plans/tuition/Tuition.vue"),
        meta: {
          title: "Tuition Reimbursement | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Services", link: "/services" },
            { name: "Tuition Reimbursement" }
          ],
          keywords: ["tuition"]
        }
      },
      {
        path: "cobra",
        name: "COBRA",
        components: {
          default: () => import("../views/Services/plans/cobra/Cobra.vue"),
          extras: () => import("../views/Services/plans/cobra/CobraExtras.vue")
        },
        meta: {
          title: "COBRA | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Services", link: "/services" },
            { name: "COBRA" }
          ],
          keywords: ["COBRA", "insurance", "premiums", "COBRA Login"]
        }
      }
    ]
  },
  // {
  //   path: "/ichra",
  //   redirect: "ichra/how-does-it-work",
  //   name: "ICHRA",
  //   component: () => import("../views/ICHRA/ICHRA.vue"),
  //   meta: {
  //     title: "ICHRA | Rocky Mountain Reserve",
  //     breadcrumb: [{ name: "Home", link: "/" }, { name: "ICHRA" }],
  //     keywords: ["Small Business"]
  //   },
  //   children: [
  //     {
  //       path: "how-does-it-work",
  //       name: "How Does It Work",
  //       component: () => import("../views/ICHRA/subviews/Details.vue"),
  //       meta: {
  //         title: "ICHRA - How Does It Work | Rocky Mountain Reserve",
  //         breadcrumb: [
  //           { name: "Home", link: "/" },
  //           { name: "ICHRA", link: "/ichra" },
  //           { name: "How Does It Work" }
  //         ],
  //         breadcrumbLight: true
  //       }
  //     },
  //     {
  //       path: "what-are-the-benefits",
  //       name: "What Are The Benefits",
  //       component: () => import("../views/ICHRA/subviews/Benefits.vue"),
  //       meta: {
  //         title: "ICHRA - What Are The Benefits | Rocky Mountain Reserve",
  //         breadcrumb: [
  //           { name: "Home", link: "/" },
  //           { name: "ICHRA", link: "/ichra" },
  //           { name: "What Are The Benefits" }
  //         ],
  //         breadcrumbLight: true
  //       }
  //     },
  //     {
  //       path: "how-do-i-qualify",
  //       name: "How Do I Qualify",
  //       component: () => import("../views/ICHRA/subviews/Qualification.vue"),
  //       meta: {
  //         title: "ICHRA - How Do I Qualify | Rocky Mountain Reserve",
  //         breadcrumb: [
  //           { name: "Home", link: "/" },
  //           { name: "ICHRA", link: "/ichra" },
  //           { name: "How Do I Qualify" }
  //         ],
  //         breadcrumbLight: true
  //       }
  //     }
  //   ]
  // },
  {
    path: "/employer-training",
    name: "Employer Training",
    component: () => import("../views/EmployerTraining/EmployerTraining.vue"),
    meta: {
      title: "Employer Training | Rocky Mountain Reserve",
      breadcrumb: [{ name: "Home", link: "/" }, { name: "Employer Training" }],
      breadcrumbLight: true
    }
  },
  {
    path: "/employer-training/cobra",
    redirect: "employer-training/cobra/training-1",
    name: "CobraTraining",
    component: () =>
      import("../views/EmployerTraining/CobraTraining/CobraTraining.vue"),
    meta: {
      title: "Cobra Training | Rocky Mountain Reserve",
      breadcrumb: [
        { name: "Home", link: "/" },
        { name: "Employer Training" },
        { name: "Cobra Training" }
      ],
      breadcrumbLight: true
    },
    children: [
      {
        path: "training-1",
        name: "Cobra Training 1",
        component: () =>
          import(
            "../views/EmployerTraining/CobraTraining/subviews/CobraTraining1.vue"
          ),
        meta: {
          title: "Cobra Training 1 | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Employer Training", link: "/employer-training" },
            { name: "Cobra Training 1" }
          ],
          breadcrumbLight: true
        }
      },
      {
        path: "training-2",
        name: "Cobra Training 2",
        component: () =>
          import(
            "../views/EmployerTraining/CobraTraining/subviews/CobraTraining2.vue"
          ),
        meta: {
          title: "Cobra Training 2 | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Employer Training", link: "/employer-training" },
            { name: "Cobra Training 2" }
          ],
          breadcrumbLight: true
        }
      },
      {
        path: "templates",
        name: "Cobra Templates",
        component: () =>
          import(
            "../views/EmployerTraining/CobraTraining/subviews/CobraTemplates.vue"
          ),
        meta: {
          title: "Cobra Templates | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Employer Training", link: "/employer-training" },
            { name: "Cobra Templates" }
          ],
          breadcrumbLight: true
        }
      }
    ]
  },
  {
    path: "/employer-training/alegeus",
    redirect: "employer-training/alegeus/training-1",
    name: "Alegeus Training",
    component: () =>
      import("../views/EmployerTraining/AlegeusTraining/AlegeusTraining.vue"),
    meta: {
      title: "Alegeus Training | Rocky Mountain Reserve",
      breadcrumb: [
        { name: "Home", link: "/" },
        { name: "Employer Training" },
        { name: "Alegeus Training" }
      ],
      breadcrumbLight: true
    },
    children: [
      {
        path: "training-1",
        name: "Alegeus Training 1",
        component: () =>
          import(
            "../views/EmployerTraining/AlegeusTraining/subviews/AlegeusTraining1.vue"
          ),
        meta: {
          title: "Alegeus Training 1 | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Employer Training", link: "/employer-training" },
            { name: "Alegeus Training 1" }
          ],
          breadcrumbLight: true
        }
      },
      {
        path: "training-2",
        name: "Alegeus Training 2",
        component: () =>
          import(
            "../views/EmployerTraining/AlegeusTraining/subviews/AlegeusTraining2.vue"
          ),
        meta: {
          title: "Alegeus Training 2 | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Employer Training", link: "/employer-training" },
            { name: "Alegeus Training 2" }
          ],
          breadcrumbLight: true
        }
      },
      {
        path: "templates",
        name: "Flex Templates",
        component: () =>
          import(
            "../views/EmployerTraining/AlegeusTraining/subviews/FlexTemplates.vue"
          ),
        meta: {
          title: "Alegeus Templates | Rocky Mountain Reserve",
          breadcrumb: [
            { name: "Home", link: "/" },
            { name: "Employer Training", link: "/employer-training" },
            { name: "Alegeus Templates" }
          ],
          breadcrumbLight: true
        }
      }
    ]
  },
  {
    path: "/employer-training/elevate",
    name: "Elevate Training",
    component: () =>
      import("../views/EmployerTraining/ElevateTraining/ElevateTraining.vue"),
    meta: {
      title: "Elevate Training | Rocky Mountain Reserve",
      breadcrumb: [
        { name: "Home", link: "/" },
        { name: "Employer Training", link: "/employer-training" },
        { name: "Elevate Training" }
      ],
      breadcrumbLight: true
    }
  },
  // {
  //   path: "/quote",
  //   name: "Get A Quote",
  //   component: () => import("../views/Quote/Quote.vue"),
  //   meta: {
  //     title: "Get A Quote | Rocky Mountain Reserve",
  //     breadcrumb: [{ name: "Home", link: "/" }, { name: "Get A Quote" }],
  //     breadcrumbLight: true,
  //     keywords: ["quote", "request a quote", "pricing", "price"]
  //   }
  // },
  {
    path: "/hsa-participants",
    beforeEnter() {
      location.href =
        "https://rmrbenefits.wealthcareportal.com/Page/Registration";
    }
  },
  {
    path: "/upgrade/wave-rmr0-employers",
    name: "Elavate Transition for Employers",
    component: () => import("../views/Microsites/wave-rmr/Employer.vue"),
    meta: {
      title: "Upgrade - RMR | Rocky Mountain Reserve"
    }
  },
  {
    path: "/upgrade/wave-rmr-employees",
    name: "Elavate Transition for Employees",
    component: () => import("../views/Microsites/wave-rmr/Employee.vue"),
    meta: {
      title: "Upgrade - RMR | Rocky Mountain Reserve"
    }
  },
  {
    path: "/upgrade/wave-1-employers",
    name: "Elavate Transition (Wave 1) Employers",
    component: () => import("../views/Microsites/wave-1/Employer.vue"),
    meta: {
      title: "Upgrade - RMR | Rocky Mountain Reserve"
    }
  },
  {
    path: "/upgrade/wave-1-employees",
    name: "Elavate Transition (Wave 1) for Employees",
    component: () => import("../views/Microsites/wave-1/Employee.vue"),
    meta: {
      title: "Upgrade - RMR | Rocky Mountain Reserve"
    }
  },
  {
    path: "/upgrade/wave-2-employers",
    name: "Elavate Transition (Wave 2) Employers",
    component: () => import("../views/Microsites/wave-2/Employer.vue"),
    meta: {
      title: "Upgrade - RMR | Rocky Mountain Reserve"
    }
  },
  {
    path: "/upgrade/wave-2-employees",
    name: "Elavate Transition (Wave 2) for Employees",
    component: () => import("../views/Microsites/wave-2/Employee.vue"),
    meta: {
      title: "Upgrade - RMR | Rocky Mountain Reserve"
    }
  },
  {
    path: "/upgrade/wave-3-employers",
    name: "Elavate Transition (Wave 3) Employers",
    component: () => import("../views/Microsites/wave-3/Employer.vue"),
    meta: {
      title: "Upgrade - RMR | Rocky Mountain Reserve"
    }
  },
  {
    path: "/upgrade/wave-3-employees",
    name: "Elavate Transition (Wave 3) for Employees",
    component: () => import("../views/Microsites/wave-3/Employee.vue"),
    meta: {
      title: "Upgrade - RMR | Rocky Mountain Reserve"
    }
  },
  {
    path: "/upgrade/wave-3-employers-bulk",
    name: "Elavate Transition (Wave 3 Bulk) Employers",
    component: () => import("../views/Microsites/wave-3-bulk/Employer.vue"),
    meta: {
      title: "Upgrade - RMR | Rocky Mountain Reserve"
    }
  },
  {
    path: "/upgrade/wave-3-employees-bulk",
    name: "Elavate Transition (Wave 3 Bulk) for Employees",
    component: () => import("../views/Microsites/wave-3-bulk/Employee.vue"),
    meta: {
      title: "Upgrade - RMR | Rocky Mountain Reserve"
    }
  },
  {
    path: "/terms-of-use",
    name: "Terms of Use",
    component: () => import("../views/Terms/Terms.vue"),
    meta: {
      title: "Terms of Use - RMR | Rocky Mountain Reserve"
    }
  },
  {
    path: "/upgrade/wave-rmr-employers",
    redirect: "upgrade/wave-1-employers"
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/404/404.vue"),
    meta: {
      title: "404 (Page Not Found) | Rocky Mountain Reserve",
      breadcrumb: [
        { name: "Home", link: "/" },
        { name: "404 (Page Not Found)" }
      ],
      breadcrumbLight: true,
      noSearch: true
    }
  },
  {
    path: "*",
    redirect: "/404"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

// Shamelessly stole this code from https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
